import React, { useState, useEffect } from "react";
import ProductCard from "../Elements/ProductCard";
import ProductImage1 from "../../assets/Images/Productcard/1.png";
import blank from "../../assets/Images/Productcard/blank.jpg";
import { Link } from "react-router-dom";
import ProductServices from "../../services/API/ProductServices"; //~/services/API/ProductServices
import { toast } from "react-toastify";
import ListingForm from '../AccountsSetting/SellerSetup/ListingForm';
import { BASE_URL } from "../../services/Constant";

const RecentViewedItems = () => {
  const [productData, setProductData] = useState([]);
  const [addtowishlist, setAddtoWishList] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [saveduser, savedUser] = useState([]);
  const [productmenagment, setProductManagment] = useState(false);
  let loggedIn = localStorage.getItem("user_details");
  let loggedInUser = "";
  if (loggedIn) {
    loggedInUser = JSON.parse(loggedIn);
  }
  const handleWishlist = (e, val) => {
    e.preventDefault();
    if (!loggedIn) {
      toast.error("You Must need to Signin");
      setTimeout(function () {
        window.location.href = `/signin`;
      }, 3000);
    } else {
      setIsLoading(true);
      setEnabled(true);
      ProductServices.saved(val, productData)
        .then((response) => {
          if (response.status) {
            toast.success(response.message);
            setIsLoading(false);
            setEnabled(false);
            fetchProductData();
          }
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(true);
          setEnabled(true);
        });
    }
  };
  const fetchProductData = async () => {
    try {
      ProductServices.all().then((res) => {
        console.log('res', res)
        if(res.status){
          setProductData(res.data.slice(0, 6)); // Limit to the first 5 products
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const editProduct = (e, val) => {
    e.preventDefault();
    // setProductManagment(true)
    window.location.href=`/productform/${val}`
    // window.location.href="http://localhost:3000/customerdashboard?component=component"
  };
  useEffect(() => {
    fetchProductData();
  }, []);
  return (
    <>
      <section id="product-recents-viewed">
        {/* {productmenagment ? (
          <>
            <ListingForm /> 
          </>
        ):( */}
          <>
            {productData.length > 0 ? (
          <>
            <div className="container">
              <div className="row">
                <div className="headings">
                  <h3>
                    New Items{" "}
                    <span>
                      <Link to="/AllProducts">View More</Link>
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <section id="productcard" style={{ padding: "15px 0px" }}>
              <div className="container">
                <div className="row">
                  {productData.map((product) => {
                    let productId = "";
                    let userId = "";
                    product.saved_users.forEach((saved) => {
                      productId =  saved.product_id
                      userId = saved.user_id
                    });
                    return(
                    <div className="col col-lg-2" key={product?.guid}>
                      <div className="productlist">
                        {product?.auctioned ? (
                          // <Link to={`/auctionproduct/${product.id}`}>
                          <Link to={`/auctionproduct/${product?.guid}`}>
                            {product.media.length > 0 ? (
                              <>
                                <img
                                  src={`${BASE_URL}/image/product/${product.media[0].name}`}
                                  alt={ProductImage1}
                                />
                              </>
                            ) : (
                              <>
                                <img src={blank} alt="blank" />
                              </>
                            )}
                          </Link>
                        ) : (
                          // <Link to={`/singleproduct/${product.id}`}>
                          <Link to={`/singleproduct/${product?.guid}`}>
                            {product.media.length > 0 ? (
                              <>
                                <img
                                  src={`${BASE_URL}/image/product/${product.media[0].name}`}
                                  alt={ProductImage1}
                                />
                              </>
                            ) : (
                              <>
                                <img src={blank} alt="blank" />
                              </>
                            )}
                          </Link>
                        )}
                        {product?.auctioned ? (
                          <span className="auction-badge">Auction</span>
                        ) : (
                          ""
                        )}
                        <div className="px-2">
                          {product?.auctioned ? (
                            <Link to={`/auctionproduct/${product?.guid}`}>
                              {/* <b>Bids:</b> $ {product.bids} */}
                              <h4>{product.name.substring(0, 10)}...</h4>
                              {/* <h4>{product?.description.substring(0, 15)}...</h4> */}
                            </Link>
                          ) : (
                            <Link to={`/singleproduct/${product.guid}`}>
                              {/* <b>Price:</b> $ {product.price} */}
                              <h4>{product.name.substring(0, 10)}...</h4>
                              {/* <h4>{product?.description.substring(0, 15)}...</h4> */}
                            </Link>
                          )}
                          <p>
                            <ul>
                              {product?.auctioned ? (
                                <>
                                  {product?.bids !== null && (
                                    <li className="price">
                                      ${product?.bids ? product?.bids : 0}
                                    </li>
                                  )}
                                </>
                              ) : (
                                <>
                                  {product?.price !== null &&
                                    product?.sale_price === 0 && (
                                      <li className="price">
                                        ${product?.price ? product?.price : 0}
                                      </li>
                                    )}

                                  {product?.price !== null &&
                                    product?.sale_price !== 0 && (
                                      <li className="price">
                                        $
                                        {product?.sale_price
                                          ? product?.sale_price
                                          : 0}
                                      </li>
                                    )}
                                  {product?.price !== null &&
                                    product?.sale_price !== 0 &&
                                    product?.sale_price !== null && (
                                      <li className="sale">
                                        <del>
                                          ${product?.price ? product?.price : 0}
                                        </del>
                                      </li>
                                    )}

                                  <li className="discount">
                                    {product?.sale_price !== null &&
                                      product?.sale_price !== 0 && (
                                        <li className="discount">
                                          {(
                                            ((product?.price -
                                              product?.sale_price) /
                                              product?.price) *
                                            100
                                          ).toFixed(2)}
                                          % OFF
                                        </li>
                                      )}
                                  </li>
                                </>
                              )}
                            </ul>
                            <ul>
                              {(() => {
                                if (product.user_id === loggedInUser.id) {
                                  return (
                                    <li>
                                      <nav>
                                        <a
                                          href="#"
                                          onClick={(e) =>
                                            editProduct(e, product.guid)
                                          }
                                        >
                                          Edit
                                        </a>{" "}
                                      </nav>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <div>
                                      {(productId === product.id) ? (
                                        <>
                                          {(userId === loggedInUser.id)? (
                                            <>
                                            Wishlist Added
                                            </>
                                          ):(
                                            <>
                                            
                                            </>
                                          )}
                                        </>
                                      ):(
                                        <><a
                                        href="#"
                                        onClick={(e) =>
                                          handleWishlist(e, product.guid)
                                        }
                                      >
                                        {/* {addtowishlist} */}
                                        Add to WishList
                                      </a></>
                                      )}
                                      {/* {saveduser.length > 0 ? (
                                        <>
                                        {saveduser.map((saved) => {
                                          return(
                                            <>
                                            {saved.product_id === product.id ? (
                                              <>
                                              {saved.user_id === loggedInUser.id ? (
                                                <>
                                                Wishlist Added</>
                                              ):(
                                                <>
                                                </>
                                              )}
                                              </>
                                            ):(
                                              <></>
                                            )}
                                            </>
                                          )
                                        })}
                                        </>
                                      ):(
                                        <></>
                                      )} */}
                                      {/* {isLoading ? ("loading..") : (<> */}
                                      {/* </>)} */}
                                    </div>
                                  );
                                }
                              })(<></>)}
                            </ul>
                          </p>
                        </div>
                      </div>
                    </div>
                  )})}
                </div>
              </div>
            </section>
          </>
        ) : (
          ""
        )}
          </>
         {/* )}  */}
      </section>
    </>
  );
};

export default RecentViewedItems;
