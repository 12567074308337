import React from 'react';
import { Button } from 'react-bootstrap'
import FeaturedBanner from "../../../components/Elements/FeaturedBanner"
import { Link } from "react-router-dom";

const FeaturedProducts = () => {
  return (
    <>
    
    <section id='featuredproduct'>
        <div className='container noenCon'>
            <div className='row'>
                <FeaturedBanner />
            </div>
        </div>
    </section>
    </>
  )
}

export default FeaturedProducts