import React, { useEffect, useState } from 'react';
import SellerProductImage2 from "../../../assets/Images/Categorylisting/2.png";
import icon1 from "../../../assets/Images/icons/1.png";
import icon2 from "../../../assets/Images/icons/2.png";
import icon3 from "../../../assets/Images/icons/3.png";
import Backimage from '../../../assets/Images/back-icon.png'
import blank from "../../../assets/Images/Productcard/blank.jpg";
import Location from "../../../assets/Images/map.png";
import StockServices from "../../../services/API/StockServices"; //~/services/API/StockServices
import { toast } from "react-toastify";
import { Link } from 'react-router-dom';
import Prdimage1 from '../../../assets/Images/Singleproduct/Product1.png';
import moment from "moment";
import SellerAllTabs from '../SellerSetup/SellerAllTabs';
import { BASE_URL } from "../../../services/Constant";

const InStock = () => {
  const [stock, setStock] = useState("");
  const getStock =() =>{
    StockServices.stockIn()
    .then((response) => {
       setStock(response.data);
    });
  }
  useEffect(() => {
    getStock();
  }, []);
  return (
    <>
      <section id='activity-main-dashboard'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className="main-content">
            <section id='searchhistory'>
                <div className='row'>
                  <div className='title-buton'>
                    <div>
                      <h3>In Stock</h3>
                    </div>
                  </div>
                </div>
                <br />
                <div className='row'>
                   {stock.length > 0 ?(<>
                    <table width="100%">
                      <thead>
                        <tr>
                          <th>Sno</th>
                          <th>Listing Date</th>
                          <th>Quantity</th>
                          <th>Product Description</th>
                        </tr>
                      </thead>
                      <tbody>
                    {stock.map((item, index) => {
                        const attributes = JSON.parse(item.products?.attributes);
                      return(
                        <>
                          <tr>
                            <td>{index+1}</td>
                            <td>{item.listingdate}</td>
                            <td>{item.quantity}</td>
                            <td>{item.products ?(
                              <><div className='historylist' key={index}>
                              <div className='list-inline'>
                                <div>
                                {item.product?.media.length > 0 ? (
                                    <>
                                      <img
                                        src={`${BASE_URL}/image/product/${item?.product.media[0].name}`}
                                        alt={item.products.name}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img src={blank} alt="blank" with="150" height="150" />
                                    </>
                                  )}
                                  {/* <img src={Prdimage1} width="150" height="150" alt='Search Icon' /> */}
                                </div>
                                <div  className="detaildashbritemdetail">
                                <h4>Name: {item.products.name}</h4>
                                {item.products.auctioned?(<>
                                  <p>Product Type: Auctioned</p>
                                </>):(<>
                                <p>Product Type: Normal Product</p></>)}
                                {item.products.auctioned ?(<>
                                  <p>Bids: $ {item.products.bids}</p>
                                </>):(<>
                                  <p>Price: $ {item.products.price}</p>
                                </>)}
                                <br />
                                <h4>Attributes:</h4>
                                  {attributes.length > 0 ? (
                                    <>
                                    
                                      {attributes.map((attribute) => {
                                        return(
                                          <>
                                            <ul>
                                              <li>Size: {attribute.size}</li>
                                              <li>Color Available: <div style={{ width: "130px", height: "30px", backgroundColor: attribute.color, color:attribute.color}}>available colors</div></li>
                                            </ul>
                                          </>
                                        )
                                      })}
                                    </>
                                  ):('')}
                                </div>
                              </div>
                              <hr />
                            </div> </>
                            ):(<></>)}</td>
                          </tr>
                        </>
                      )
                    })}
                    </tbody>
                    </table>
                   </>):('No Stock')}
                </div>
              </section>
            <button className='backbutton-account' onClick={() => window.history.back()}><img src={Backimage} /> Back</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InStock;
