import React, { useState, useEffect } from 'react';
import ProductCard from "../Elements/ProductCard"
import ProductImage1 from "../../assets/Images/Productcard/1.png";
import { Link } from "react-router-dom";
import blank from "../../assets/Images/Productcard/blank.jpg";
import ProductServices from '../../services/API/ProductServices'; //~/services/API/ProductServices
import { BASE_URL } from "../../services/Constant";
import { toast } from "react-toastify";

const RecentViewedItems = () => {
    const [productData, setProductData] = useState([]);
    const fetchProductData = async () => {
      try {
        ProductServices.recent()
        .then((response) => {
        setProductData(response.slice(0, 6)); // Limit to the first 5 products
      })
      } catch (error) {
        toast.error(error);
      }
    };
    useEffect(() => {
      fetchProductData();
    }, []);
  return (
    <>
    <section id='product-recents-viewed'>
    {productData.length > 0 ?(
              <>
        <div className='container'>
            <div className='row'>
                <div className='headings'>
                <h3> Recent Views{" "} <span><Link to="/AllProducts">View More</Link></span></h3>
                </div>
            </div>
        </div>
        <section id='productcard' style={{ padding: "15px 0px" }}>
        <div className='container'>
          <div className='row'>
              {productData.map((product) => {
                return(
                  <div className='col col-lg-2' key={product.products?.guid}>
                    <div className='productlist'>
                      {product?.auctioned ? (
                        // <Link to={`/auctionproduct/${product.id}`}>
                        <Link to={`/auctionproduct/${product.products?.guid}`}>
                          {product.products?.media.length > 0 ? (
                                  <>
                                    <img
                                      src={`${BASE_URL}/image/product/${product.products?.media[0].name}`}
                                      alt={`${product.products?.media[0].name}`}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img src={blank} alt="blank" />
                                  </>
                                )}
                          {/* <img src={product.cover_image} alt={product.name} /> */}
                        </Link>
                      ) : (
                        // <Link to={`/singleproduct/${product.id}`}>
                        <Link to={`/singleproduct/${product.products?.guid}`}>
                              {product.products?.media.length > 0 ? (
                                  <>
                                    <img
                                      src={`${BASE_URL}/image/product/${product.products?.media[0].name}`}
                                      alt={`${product.products?.media[0].name}`}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img src={blank} alt="blank" />
                                  </>
                                )}                      {/* <img src={product.cover_image} alt={product.name} /> */}
                        </Link>
                      )}
                      {product.products?.auctioned ?(<span className='auction-badge'>Auction</span>) : ('')}
                      <div className='px-2'>
                        {product.products?.auctioned ? (
                          <Link to={`/auctionproduct/${product.products?.guid}`}>
                            <h4>{product.products?.name.substring(0, 20)}...</h4>
                          </Link>
                        ) : (
                          <Link to={`/singleproduct/${product.products?.guid}`}>
                          <h4>{product.products?.name.substring(0, 20)}...</h4>
                          </Link>
                        )}
                        <p>
                          <p>
                            <ul>
                              {product.products?.auctioned ? (
                                    <>
                                      {product.products?.bids !== null && (
                                        <li className="price">
                                          ${product.products?.bids ? product.products?.bids : 0}
                                        </li>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {product.products?.price !== null &&
                                        product.products?.sale_price === 0 && (
                                          <li className="price">
                                            ${product.products?.price ? product.products?.price : 0}
                                          </li>
                                        )}
    
                                      {product.products?.price !== null &&
                                        product.products?.sale_price !== 0 && (
                                          <li className="price">
                                            $
                                            {product.products?.sale_price
                                              ? product.products?.sale_price
                                              : 0}
                                          </li>
                                        )}
                                      {product.products?.price !== null &&
                                        product.products?.sale_price !== 0 &&
                                        product.products?.sale_price !== null && (
                                          <li className="sale">
                                            <del>
                                              ${product.products?.price ? product.products?.price : 0}
                                            </del>
                                          </li>
                                        )}
    
                                      <li className="discount">
                                        {product.products?.sale_price !== null &&
                                          product.products?.sale_price !== 0 && (
                                            <li className="discount">
                                              {(
                                                ((product.products?.price -
                                                  product.products?.sale_price) /
                                                  product.products?.price) *
                                                100
                                              ).toFixed(2)}
                                              % OFF
                                            </li>
                                          )}
                                      </li>
                                    </>
                                  )}
                              {/* {product.products?.price !== null && (
                                <li className='price'>${product.products?.price}</li>
                              )}
                              {product.products?.price !== null && product.products?.sale_price !== null && (
                                <li className='sale'>
                                  <del>${product.products?.price}</del>
                                </li>
                              )}
                              {product.products?.price !== null && product.products?.sale_price !== null && (
                                <li className='discount'>
                                  {((product.products?.price - product.products?.sale_price) / product.products?.price * 100).toFixed(2)}% OFF
                                </li>
                              )} */}
                            </ul>
                          </p>
                        </p>
                      </div>
                    </div>
                  </div>
                )
              })}
             
          </div>
        </div>
      </section>
      </>
      ):('')}
    </section>
    </>
  )
}

export default RecentViewedItems