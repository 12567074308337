import React, { useState, useEffect } from "react";
import ProductImage1 from "../../assets/Images/Productcard/1.png";
import ProductImage2 from "../../assets/Images/Productcard/2.png";
import ProductImage3 from "../../assets/Images/Productcard/3.png";
import ProductImage4 from "../../assets/Images/Productcard/4.png";
import ProductImage5 from "../../assets/Images/Productcard/5.png";
import blank from "../../assets/Images/Productcard/blank.jpg";
import closeImg from "../../assets/Images/close.png";
import ListingForm from "../AccountsSetting/SellerSetup/ListingForm";
import { Link } from "react-router-dom";
import ProductServices from "../../services/API/ProductServices"; //~/services/API/ProductServices
import SellerServices from "../../services/API/SellerServices"; //~/services/API/SellerServices
import { toast } from "react-toastify";
import { BASE_URL } from "../../services/Constant";
const ProductCardDetails = (props) => {
  let product = props.product;
  let featured = props.featured;
  console.log('featured', featured)
  const [productData, setProductData] = useState([]);
  const [showPopup, setShowPopup] = useState(false); // State for showing the popup
  const [guid, setGuid] = useState(""); // State for showing the popup
  const [submitted, setSubmitted] = useState(false);
  const [featuredproducts, setFeaturedProducts] = useState("");
  let loggedIn = localStorage.getItem('user_details');
  let loggedInUser="";
  if(loggedIn){
    loggedInUser = JSON.parse(loggedIn)
  };
  console.log('product data', product)
  const editProduct = (e, val) => {
    e.preventDefault();
   
  };
  
  useEffect(() => {
    //
  }, []);
  return (
    <>
      <section id="productcard" style={{ padding: "15px 0px" }}>
        <div className="container">
          <div className="row">
                      <div className="col col-lg-2" key={product.guid}>
                        <div className="productlist">
                          {product.auctioned ? (
                            <>
                              {/* <Link to={`/auctionproduct/${product.id}`}> */}
                              <Link to={`/auctionproduct/${product.guid}`}>
                              {product.media.length == 0? (
                                <>
                                  <img
                                    src={blank}
                                    alt="blank"
                                  />
                                </>
                              ):(
                                <>
                                {product.media?.map((media, index) => {
                                  if (index == 0) {
                                    return (
                                      <>
                                        <Link
                                          to={`/singleproduct/${product.guid}`}
                                        >
                                          <img
                                            src={`${BASE_URL}/image/product/${media.name}`}
                                            alt={product.name}
                                          />
                                          {/* <img src={product.cover_image} alt={product.name} /> */}
                                        </Link>
                                      </>
                                    );
                                  }
                                })}
                                </>
                              )}
                                
                                {/* <img src={product.cover_image} alt={product.name} /> */}
                              </Link>
                            </>
                          ) : (
                            <>
                            {product.media.length == 0? (
                                <>
                                  <img
                                    src={blank}
                                    alt="blank"
                                  />
                                </>):(
                                  <>
                                  {product.media?.map((media, index) => {
                                      if (index == 0) {
                                        return (
                                          <>
                                            <Link
                                              to={`/singleproduct/${product.guid}`}
                                            >
                                              <img
                                                src={`${BASE_URL}/image/product/${media.name}`}
                                                alt={product.name}
                                              />
                                              {/* <img src={product.cover_image} alt={product.name} /> */}
                                            </Link>
                                          </>
                                        );
                                      }
                                    })}
                                  </>
                                )}
                              
                              {/* <Link to={`/singleproduct/${product.id}`}> */}
                            </>
                          )}
                          {product.auctioned ? (
                            <span className="auction-badge">Auction</span>
                          ) : (
                            ""
                          )}
                          <div className="px-2">
                            {product.auctioned ? (
                              <Link to={`/auctionproduct/${product.guid}`}>
                                <h4>{product.name.substring(0, 10)}...</h4>
                                {/* <h4>
                                  {product.description.substring(0, 8)}...
                                </h4> */}
                              </Link>
                            ) : (
                              <Link to={`/singleproduct/${product.guid}`}>
                                <h4>{product.name.substring(0, 10)}...</h4>
                                {/* <h4>
                                  {product.description.substring(0, 8)}...
                                </h4> */}
                              </Link>
                            )}
                            <p>
                              <h4><b>Condition:</b></h4>
                              {product.condition}
                            </p>
                            <p>
                              {product.auctioned ? (
                                <>
                                  <ul>
                                      {product?.bids !== null && (
                                          <li className='price'>${product?.bids ? product?.bids : 0}</li>
                                      )}
                                  </ul>
                                </>
                              ) : (
                                <>
                                  <ul>
                                  <>
                                        {product?.price !== null  &&  product?.sale_price === 0 && (
                                          <li className='price'>${product?.price ? product?.price : 0}</li>
                                        )}
              
                                          {product?.price !== null  &&  product?.sale_price !== 0 && (
                                          <li className='price'>${product?.sale_price ? product?.sale_price : 0}</li>
                                        )}
                                          {product?.price !== null &&  product?.sale_price !== 0 && product?.sale_price !== null && (
                                            <li className='sale'>
                                              <del>${product?.price ? product?.price : 0}</del>
                                            </li>
                                          )}  
                                         
                                            <li className='discount'>
                                              {product?.sale_price !== null &&  product?.sale_price !== 0 && (
                                                <li className='discount'>{((product?.price - product?.sale_price) / product?.price * 100).toFixed(2)}% OFF</li>
                                              )}
                                              
                                            </li>
                                         
                                        </>
                                  </ul>
                                </>
                              )}
                              <ul>
                                {(() => {
                                  if (product.user_id === loggedInUser.id) {                                    
                                    return (
                                      <li>
                                        <nav>
                                          <a
                                            href="#"
                                            onClick={(e) =>
                                              editProduct(e, product.guid)
                                            }
                                          >
                                            Edit
                                          </a>{" "}
                                        </nav>
                                      </li>
                                    );
                                  } else {
                                    return <div>&nbsp;</div>;
                                  }
                                })()}
                              </ul>
                            </p>
                          </div>
                        </div>
                      </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductCardDetails;
