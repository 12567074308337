import React, { useEffect, useState } from 'react'
import Searchicon from "../../assets/Images/Elements/search.png"
import Category from "../../services/API/Category"; //~/services/API/Category
var Searchbg = {
    backgroundImage: `url(${Searchicon})`,
    backgroundSize: "100% 100%"
  };

const SearchwithCategories = () => {
  const [categories, setCategory] = useState({});
  const [selectedcategories, setSelectedCategory] = useState("");
  const [selectedkeyword, setSelectedKeyword] = useState("");
  const getCategory = () => {
    Category.all()
    .then((response) => {
      setCategory(response)
    })
  }
  const handleCategoryChange = (e)=> {
    e.preventDefault();
    setSelectedCategory(e.target.value)
    // console.log('catagory selected', e.target.value)
  }

  const handleCategoryDetails = (e)=> {
    e.preventDefault();
    if(e.target.value.length){
      setSelectedKeyword(e.target.value)
      localStorage.setItem('selected_key', e.target.value)
      setTimeout(() => {
        if(selectedcategories !== ""){
          window.location.href=`/categorykeyword/${selectedcategories}`
        }
      }, 2000);
    }
  }
  useEffect(() => {
    getCategory()
  }, [])
  return (
    <>
    <form id="searchForm">
    <div className="input-group se">
    <input type="submit" value="Search" 
    style={Searchbg}
    />
  </div>
  <div className="input-group">
    <input type="text" id="search" name="search" onChange={handleCategoryDetails} placeholder='Search Here Anything.....' />
  </div>
  <div className="input-group categoruu">
    <select id="category" name="category" onChange={handleCategoryChange}>
      <option value="all">Categories</option>
      {categories.length > 0 ? (
        <>
          {categories?.map(category => {
            return (
              <option key={category.id} value={category.guid}>
                <a href="#"><b>{category.name}</b></a>
                {category.children_recursive.length > 0 ?(
                  <>
                  <ul className="dropdown-menu shadow">
                    {category.children_recursive.map(child => {
                      return(
                        <>
                        <li key={child.id}><a href="#" onChange={handleCategoryChange}><b>{child.name}</b></a></li>
                        </>
                      )
                    })}
                    </ul>
                  </>
                ):('')}
                
              </option>
            )
          } )}
        </>
      ) :('')}
    </select>
  </div>
  
</form>
    </>
  )
}

export default SearchwithCategories