import React, { useState, useEffect } from "react";
import Bannerimage from "../../assets/Images/Elements/banner.png";
import Bannerimage1 from "../../assets/Images/Elements/banner2.png";
import Arrow from "../../assets/Images/Elements/arrow.png";
import SellerServices from "../../services/API/SellerServices"; //~/services/API/SellerServices
import ProductCard from "../Elements/ProductCard";
import { Link } from "react-router-dom";

var Bannerbg = {
  backgroundImage: `url(${Bannerimage})`,
  backgroundSize: `cover`,
};
var Bannerbgs = {
  backgroundImage: `url(${Bannerimage1})`,
  backgroundSize: `cover`,
};

const FeaturedBanner = () => {
  const [featured, setFeatured] = useState([]);
  const fetchFeatured = async () => {
    try {
      SellerServices.getFeatured().then((response) => {
        if (response.status) {
          setFeatured(response.data);
        }
      });
    } catch (error) {
      console.error("error:", error);
    }
  };
  useEffect(() => {
    fetchFeatured();
  }, []);

  return (
    <>
      <section id="banners">
        <div className="container">
          <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-bs-ride="true"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                {featured.length > 0 ? (
                  <>
                    {featured.map((store, index) => {
                      return (
                        <>
                          <div className="headings pr-auto">
                            <h3 style={{ color: "white", paddingLeft: "50px" }}>
                              {store.fullname} Featured Products
                              <span
                                style={{
                                  float: "right",
                                  paddingLeft: "58px",
                                  fontSize: "18px",
                                  color: "white",
                                  background: "#723780",
                                  paddingRight: "10px",
                                }}
                              >
                                Sponsored
                              </span>
                            </h3>
                          </div>
                          <div className="row render">
                            <ProductCard featured={store.guid} />
                          </div>
                          <div className="row">
                            <a href={`/SellerShop/${store.guid}`}
                              style={{ textDecoration: "unset" }}
                              target="_blank"
                            >
                              <button className="btnFeature">View More</button>
                            </a>
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            {featured.length > 0 ? (
                  <>
                    {featured.map((store, index) => {
                      return (
                        <>
                          <div class="carousel-indicators">
                            <button
                              type="button"
                              data-bs-target="#carouselExampleIndicators"
                              data-bs-slide-to="0"
                              class="active"
                              aria-current="true"
                              aria-label="Slide 1"
                            ></button>
                          </div>
                        </>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturedBanner;
