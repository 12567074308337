import React, { useState, useEffect } from "react";
import ProductCard from "../../Elements/ProductCardDetails";
import Header from "../../Header";
import Footer from "../../Footer";
import GetSurprisedBanner from "../../Elements/GetSurprisedBanner";
import SubcategoriesList from "../../Elements/FilterAttributes/SubcategoriesList";
import Search from "../../Elements/FilterAttributes/Search";
import PriceRange from "../../Elements/FilterAttributes/PriceRange";
import SizeToggle from "../../Elements/FilterAttributes/Size";
import SellerServices from "../../../services/API/SellerServices"; //~/services/API/SellerServices
import ProductServices from "../../../services/API/ProductServices"; //~/services/API/ProductServices

const Recomendations = () => {
  const [featured, setFeatured] = useState([]);
  const [productData, setProductData] = useState([]);
  const fetchFeatured = async () => {
    try {
      SellerServices.getFeatured().then((response) => {
        if (response.status) {
          setFeatured(response.data);
        }
      });
    } catch (error) {
      console.error("error:", error);
    }
  };
  const featuredProducts = () => {
    if (featured) {
        let featuredShops = [];
        featured.forEach(function (feature) {
            featuredShops.push(feature.id)
        });
        let data ={
            "shops": featuredShops
        }
        ProductServices.getRecomemdedProducts(featuredShops)
        .then((res) => {
          if (res.status) {
            console.log('recomended', res.data)
            // setProductData(res.data);
          }
        })
        .catch((e) => {
          console.log(e.message);
        });
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [cardsPerPage] = useState(6); // Change this value to adjust items per page

  // Mock data for products (replace this with your actual data)
  const products = [
    {
      id: 1,
      name: "Product 1",
      description: "Description for Product 1",
      price: 10.99,
    },
    {
      id: 2,
      name: "Product 2",
      description: "Description for Product 2",
      price: 19.99,
    },
    {
      id: 3,
      name: "Product 3",
      description: "Description for Product 3",
      price: 14.99,
    },
    {
      id: 4,
      name: "Product 4",
      description: "Description for Product 4",
      price: 9.99,
    },
    {
      id: 5,
      name: "Product 5",
      description: "Description for Product 5",
      price: 24.99,
    },
    {
      id: 6,
      name: "Product 6",
      description: "Description for Product 6",
      price: 29.99,
    },
    {
      id: 7,
      name: "Product 7",
      description: "Description for Product 7",
      price: 12.99,
    },
    {
      id: 8,
      name: "Product 8",
      description: "Description for Product 8",
      price: 17.99,
    },
    {
      id: 9,
      name: "Product 9",
      description: "Description for Product 9",
      price: 21.99,
    },
    // Add more mock products as needed
  ];

  // Logic to paginate product cards
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = productData.slice(indexOfFirstCard, indexOfLastCard);

  const renderProductCards = () => {
    return currentCards.map((product) => <ProductCard product={product} />);
  };

  // Logic to handle page changes
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate total number of pages
  const totalPages = Math.ceil(products.length / cardsPerPage);
  useEffect(() => {
    fetchFeatured();
    featuredProducts();
  }, []);
  return (
    <>
      {/* Header */}
      <Header />
      {/* Header */}

      <section
        id="Auctions"
        style={{
          padding: "30px 0px",
        }}
      >
        <div className="container">
          <h2>Recomended Items</h2>
          <div className="row">
            <div className="col-lg-3">
              <div id="all-filters">
                <h3 style={{ color: "#000" }}>Filters</h3>
                <SubcategoriesList />
                <Search />
                <PriceRange />
                <SizeToggle />
              </div>
            </div>
            <div className="col-lg-9">
              {renderProductCards()}
              {/* Pagination */}
              <ul className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                  <li
                    key={index}
                    className={`page-item ${
                      currentPage === index + 1 ? "active" : ""
                    }`}
                  >
                    <button
                      onClick={() => paginate(index + 1)}
                      className="page-link"
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
              </ul>
              {/* Pagination */}
            </div>
          </div>
        </div>
      </section>
      {/* GetSurprisedBanner */}
      <GetSurprisedBanner />
      {/* GetSurprisedBanner */}
      {/* Footer */}
      <Footer />
      {/* Footer */}
    </>
  );
};

export default Recomendations;
