import React, { useState, useEffect } from "react";
import ProductImage1 from "../../assets/Images/Categorylisting/1.png";
import ProductImage2 from "../../assets/Images/Categorylisting/2.png";
import ProductImage3 from "../../assets/Images/Categorylisting/3.png";
import ProductImage4 from "../../assets/Images/Categorylisting/4.png";
import blank from "../../assets/Images/Productcard/blank.jpg";
import ProductImage5 from "../../assets/Images/Categorylisting/5.png";
import { Link } from "react-router-dom";
import ProductServices from "../../services/API/ProductServices"; //~/services/API/ProductServices
import { toast } from "react-toastify";
import { BASE_URL } from "../../services/Constant";
const CategoryProductListing = () => {
  const categoryFilter = "Cell Phones & Smart";
  const [productData, setProductData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const { pathname } = window.location;
  const id = pathname.split("/").pop();
  let loggedIn = localStorage.getItem("user_details");
  let loggedInUser = "";
  if (loggedIn) {
    loggedInUser = JSON.parse(loggedIn);
  }
  const editProduct = (e, val) => {
    e.preventDefault();
    // setProductManagment(true)
    window.location.href = `/productform/${val}`;
    // window.location.href="http://localhost:3000/customerdashboard?component=component"
  };
  const handleWishlist = (e, val) => {
    e.preventDefault();
    if (!loggedIn) {
      toast.error("You Must need to Signin");
      setTimeout(function () {
        window.location.href = `/signin`;
      }, 3000);
    } else {
      setIsLoading(true);
      setEnabled(true);
      ProductServices.saved(val, productData)
        .then((response) => {
          if (response.status) {
            toast.success(response.message);
            setIsLoading(false);
            setEnabled(false);
            fetchProductData();
          }
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(true);
          setEnabled(true);
        });
    }
  };
  const fetchProductData = () => {
    ProductServices.related(id)
      .then((res) => {
        if (res.status) {
          setProductData(res.data?.products);
          setCategoryData(res.data?.category);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    try {
      fetchProductData();
      // ProductServices.all()
      // .then((res) => {
      //   if(res.status){
      //       // console.log('mobiles products', response.filter(product => product.category === categoryFilter))
      //       const filteredProducts = res.data.filter(product => product.category === categoryFilter);
      //       setProductData(filteredProducts.slice(0, 5)); // Limit to the first 5 products
      //   }
      // })
    } catch (error) {
      console.error("Error fetching product data:", error);
      setError("Error fetching product data. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [categoryFilter]);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="headings">
            <h3>
              {categoryData.name}{" "}
              {/* <span>
                    <Link to="/singlecategory">View More</Link>
                  </span> */}
            </h3>
          </div>
        </div>
      </div>
      {productData.length > 0 ? (
        <>
          <section id="productcard" style={{ padding: "10px 0px" }}>
            <div className="container">
              {loading ? (
                <p>Loading...</p>
              ) : error ? (
                <p>{error}</p>
              ) : (
                <div className="row">
                  {productData.map((product) => {
                    let productId = "";
                    let userId = "";
                    product.saved_users.forEach((saved) => {
                      productId = saved.product_id;
                      userId = saved.user_id;
                    });
                    return (
                      <div className="col col-lg-3" key={product.id}>
                        <div className="productlist">
                          {product.auctioned ? (
                            <Link to={`/auctionproduct/${product.guid}`}>
                              {product.media.length == 0 ? (
                                <>
                                  <img src={blank} alt="blank" />
                                </>
                              ) : (
                                <>
                                  {product.media?.map((media, index) => {
                                    if (index == 0) {
                                      return (
                                        <>
                                          <img
                                            src={`${BASE_URL}/image/product/${media.name}`}
                                            alt={product.name}
                                          />
                                          {/* <img src={product.cover_image} alt={product.name} /> */}
                                        </>
                                      );
                                    }
                                  })}
                                </>
                              )}
                            </Link>
                          ) : (
                            <Link to={`/singleproduct/${product.guid}`}>
                              {product.media.length == 0 ? (
                                <>
                                  <img src={blank} alt="blank" />
                                </>
                              ) : (
                                <>
                                  {product.media?.map((media, index) => {
                                    if (index == 0) {
                                      return (
                                        <>
                                          <img
                                            src={`${BASE_URL}/image/product/${media.name}`}
                                            alt={product.name}
                                          />
                                          {/* <img src={product.cover_image} alt={product.name} /> */}
                                        </>
                                      );
                                    }
                                  })}
                                </>
                              )}
                            </Link>
                          )}
                          {product.auctioned && (
                            <span className="auction-badge">Auction</span>
                          )}
                          <div className="px-2">
                            {product.auctioned ? (
                              <Link to={`/auctionproduct`}>
                                <h4>{product.name.substring(0, 10)}...</h4>
                              </Link>
                            ) : (
                              <Link to={`/singleproduct/${product.guid}`}>
                                <h4>{product.name.substring(0, 10)}...</h4>
                              </Link>
                            )}
                            <p>
                              <ul>
                                {product?.auctioned ? (
                                  <>
                                    {product?.bids !== null && (
                                      <li className="price">
                                        ${product?.bids ? product?.bids : 0}
                                      </li>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {product?.price !== null &&
                                      product?.sale_price === 0 && (
                                        <li className="price">
                                          ${product?.price ? product?.price : 0}
                                        </li>
                                      )}

                                    {product?.price !== null &&
                                      product?.sale_price !== 0 && (
                                        <li className="price">
                                          $
                                          {product?.sale_price
                                            ? product?.sale_price
                                            : 0}
                                        </li>
                                      )}
                                    {product?.price !== null &&
                                      product?.sale_price !== 0 &&
                                      product?.sale_price !== null && (
                                        <li className="sale">
                                          <del>
                                            $
                                            {product?.price
                                              ? product?.price
                                              : 0}
                                          </del>
                                        </li>
                                      )}

                                    <li className="discount">
                                      {product?.sale_price !== null &&
                                        product?.sale_price !== 0 && (
                                          <li className="discount">
                                            {(
                                              ((product?.price -
                                                product?.sale_price) /
                                                product?.price) *
                                              100
                                            ).toFixed(2)}
                                            % OFF
                                          </li>
                                        )}
                                    </li>
                                  </>
                                )}
                                {/* {product.sale_price !== null && (
                                  <li className="price">${product.sale_price}</li>
                                )}
                                {product.price !== null &&
                                  product.sale_price !== null && (
                                    <li className="sale">
                                      <del>${product.price}</del>
                                    </li>
                                  )}
                                {product.price !== null &&
                                  product.sale_price !== null && (
                                    <li className="discount">
                                      {(
                                        ((product.price - product.sale_price) /
                                          product.price) *
                                        100
                                      ).toFixed(2)}
                                      % OFF
                                    </li>
                                  )} */}
                              </ul>
                              <ul>
                                {(() => {
                                  if (product.user_id === loggedInUser.id) {
                                    return (
                                      <li>
                                        <nav>
                                          <a
                                            href="#"
                                            onClick={(e) =>
                                              editProduct(e, product.guid)
                                            }
                                          >
                                            Edit
                                          </a>{" "}
                                        </nav>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <div>
                                        {productId === product.id ? (
                                          <>
                                            {userId === loggedInUser.id ? (
                                              <>Wishlist Added</>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <a
                                              href="#"
                                              onClick={(e) =>
                                                handleWishlist(e, product.guid)
                                              }
                                            >
                                              {/* {addtowishlist} */}
                                              Add to WishList
                                            </a>
                                          </>
                                        )}
                                      </div>
                                    );
                                  }
                                })(<></>)}
                              </ul>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </section>
        </>
      ) : (
        "No Product Exits"
      )}
    </>
  );
};

export default CategoryProductListing;
